@font-face {
  font-family: "Jost";
  src: url("./fonts/Jost-Regular.ttf");
}

@font-face {
  font-family: "Kanit";
  src: url("./fonts/Kanit-Regular.ttf");
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins400.ttf");
}
@font-face {
  font-family: "DMSans";
  src: url("./fonts/DMSans-Regular.ttf");
}

@font-face {
  font-family: "StraitRegular";
  src: url("./fonts/StraitRegular.ttf");
}
@font-face {
  font-family: "Monda";
  src: url("./fonts/Monda-Regular.ttf");
}

body {
  margin: 0;
  font-family: "DMSans", "Jost", "Poppins", "Kanit", "Monda", "StraitRegular",
    -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
::-webkit-scrollbar {
  width: 16px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 8px;
  /* background-color: #e7e7e7; */
  /* border: 1px solid #cacaca; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 3px solid transparent;
  background-clip: content-box;
  background-color: #d1ac64;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d1ac64;
}
